import { DAY_IN_SECONDS } from '@constants/time';
import { translations } from '@hooks/useTranslations';
import NotFoundTemplate from '@templates/NotFoundTemplate/NotFoundTemplate';
import { Locale } from '@type-declarations/locale';
import { NotFoundPage } from '@type-declarations/page';

function Custom404Page({ page }: { page: NotFoundPage }) {
  return <NotFoundTemplate page={page} />;
}

export async function getStaticProps({ locale }: { locale: Locale }) {
  const host = 'generic'; // Only used for 404 and 500 pages

  return {
    props: {
      host,
      locale,
      page: {
        title: translations[locale].notFoundPageTitle || 'Pagina niet gevonden',
        seo: {
          metaTitle:
            translations[locale].notFoundPageTitle || 'Pagina niet gevonden',
        },
        hero: {
          title:
            translations[locale].notFoundPageHeroTitle ||
            'Oeps... Het lijkt erop dat de pagina die je zoekt niet kan worden gevonden',
        },
        ctaTitle:
          translations[locale].notFoundPageCtaText || 'Terug naar de homepage',

        ctaLink: `/${locale}`,
        sections: [],
      },
      config: {
        footer: null,
        mainMenu: null,
      },
    },
    revalidate: DAY_IN_SECONDS,
  };
}

export default Custom404Page;
