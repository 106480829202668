import Button from '@atoms/Button/Button';
import ContentHero from '@organisms/ContentHero/ContentHero';
import Sections from '@organisms/Sections/Sections';
import { NotFoundPage } from '@type-declarations/page';

export default function NotFoundTemplate({
  page: { title, hero, sections, ctaLink, ctaTitle },
}: {
  page: NotFoundPage;
}) {
  return (
    <>
      <ContentHero
        shortTitle={title}
        longTitle={hero?.title}
        text={hero?.text}
        image={hero?.image}
        focusPoint={hero?.focusPoint}
      >
        {ctaLink && ctaTitle && (
          <Button
            href={ctaLink}
            label={ctaTitle}
            variant="secondary"
            // forceHardNavigate so the favicons get updated when navigating to a different domain
            forceHardNavigate
          />
        )}
      </ContentHero>

      <Sections sections={sections} />
    </>
  );
}
